'use client'

import {
  Box,
  Center,
  Grid,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Stack,
  Text,
  useColorMode,
  useColorModeValue,
  useMediaQuery,
} from '@chakra-ui/react'
import {
  Connected,
  Connecting,
  Disconnected,
  NotExist,
  Rejected,
  Error,
  WalletConnectComponent,
} from './react'
import React, { MouseEventHandler } from 'react'
import { defaultChainName } from '../config'
import { useChain } from '@cosmos-kit/react'
import { motion } from 'framer-motion'
import { HamburgerIcon } from '@chakra-ui/icons'
import Image from 'next/image'

const NavLink = ({
  href,
  label,
  onClick,
}: {
  href?: string
  label: string
  onClick?: (event: any) => void
}) => {
  return (
    <Link href={href} onClick={onClick} _hover={{}}>
      <Text fontSize={{ sm: 8, lg: 16 }} fontWeight={500} _hover={{ color: 'secondary.base' }}>
        {label}
      </Text>
    </Link>
  )
}

export default function Nav({ isWaiting, chain }: { isWaiting: boolean; chain?: string }) {
  const { colorMode, toggleColorMode } = useColorMode()
  const { status, connect, openView, username } = useChain(chain || defaultChainName)
  const [isLargerThan600] = useMediaQuery('(min-width: 600px)', {
    ssr: true,
    fallback: false,
  })

  const onClickConnect: MouseEventHandler = async e => {
    e.preventDefault()
    await connect()
  }

  const onClickOpenView: MouseEventHandler = e => {
    e.preventDefault()
    openView()
  }

  const connectWalletButton = (
    <WalletConnectComponent
      walletStatus={status}
      disconnect={<Disconnected buttonText="Connect" onClick={onClickConnect} />}
      connecting={<Connecting />}
      connected={
        <Connected buttonText={username ? username : 'My Wallet'} onClick={onClickOpenView} />
      }
      rejected={<Rejected buttonText="Reconnect" onClick={onClickConnect} />}
      error={<Error buttonText="Change Wallet" onClick={onClickOpenView} />}
      notExist={<NotExist buttonText="Install Wallet" onClick={onClickOpenView} />}
    />
  )

  const largeNav = (
    <Box h={16}>
      <Box padding={6}>
        <Center>
          <Grid templateColumns="repeat(7, 1fr)" gap={10} alignItems="center" justifyItems="center">
            {/* Text elements */}
            <NavLink href={'/delegate'} label={'DELEGATE'} />
            <NavLink href={'/backdrop'} label={'BACKDROP'} />
            <NavLink href={'/rewards'} label={'REVENUE SHARE'} />

            {/* Logo */}
            <Link href={'/'}>
              <Box as={motion.div} whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.9 }}>
                <Image
                  src={useColorModeValue('/images/kleo-logo-reversed.png', '/images/kleo-logo.png')}
                  width={40}
                  height={40}
                  alt="logo"
                />
              </Box>
            </Link>
            <NavLink
              label={useColorModeValue('LIGHT MODE', 'DARK MODE')}
              onClick={toggleColorMode}
            />
            <NavLink label={'STAKE'} href={'/stake'} />
            <Stack marginX={4} direction={'row'}>
              {!isWaiting ? <>{connectWalletButton}</> : <Spinner />}
            </Stack>
          </Grid>
        </Center>
      </Box>
    </Box>
  )
  const mobileNav = (
    <Box h={16} width={'$100vw'}>
      <Box padding={6}>
        <Stack direction={'row'} spacing={4} justify={'space-between'}>
          <Link href={'/'}>
            <Box as={motion.div} whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.9 }}>
              <Image
                src={useColorModeValue('/images/kleo-logo-reversed.png', '/images/kleo-logo.png')}
                height={40}
                width={40}
                alt="logo"
              />
            </Box>
          </Link>
          <Stack marginX={4} direction={'row'}>
            {!isWaiting ? <>{connectWalletButton}</> : <Spinner />}
          </Stack>
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<HamburgerIcon color={useColorModeValue('primary.base', 'secondary.base')} />}
              variant="outline"
              borderColor={useColorModeValue('primary.700', 'secondary.700')}
            />
            <MenuList>
              <MenuItem>
                <NavLink href={'/delegate'} label={'DELEGATE'} />
              </MenuItem>
              <MenuItem>
                <NavLink href={'/stake'} label={'STAKE'} />
              </MenuItem>
              <MenuItem>
                <NavLink href={'/backdrop'} label={'BACKDROP'} />
              </MenuItem>
              <MenuItem>
                <NavLink href={'/rewards'} label={'REVENUE SHARE'} />
              </MenuItem>
              <MenuItem>
                <NavLink
                  label={useColorModeValue('LIGHT MODE', 'DARK MODE')}
                  onClick={toggleColorMode}
                />
              </MenuItem>
            </MenuList>
          </Menu>
        </Stack>
      </Box>
    </Box>
  )

  return <>{!isLargerThan600 ? mobileNav : largeNav}</>
}
