import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

export type KleoPriceResponse = {
  kleomedes: {
    usd: number
  }
}

export const useKleoPrice = () => {
  const query = useQuery<KleoPriceResponse, Error>({
    queryKey: ['kleoPrice'],
    queryFn: async () => {
      const res = await fetch(
        `https://api.coingecko.com/api/v3/simple/price?ids=kleomedes&vs_currencies=usd`,
        {
          next: { revalidate: 3600 },
        },
      )
      if (!res.ok) throw new Error(res.statusText)
      return await res.json()
    },
  })

  const data = useMemo(() => query.data, [query.data])

  return {
    isLoading: query.isLoading,
    error: query.error,
    data,
  }
}
