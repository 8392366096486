import React, { MouseEventHandler, ReactNode } from 'react'
import {
  Button,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { IoWallet } from 'react-icons/io5'
import { ConnectWalletType } from '../types'
import { FiAlertTriangle } from 'react-icons/fi'
import { WalletStatus } from '@cosmos-kit/core'
import UsernameText from '../usernameText'

export const ConnectWalletButton = ({
  buttonText,
  isLoading,
  isDisabled,
  icon,
  onClickConnectBtn,
}: ConnectWalletType) => {
  return (
    <Button
      w="full"
      minW="fit-content"
      size="lg"
      isLoading={isLoading}
      isDisabled={isDisabled}
      bgImage="linear-gradient(109.6deg, rgba(157,75,199,1) 11.2%, rgba(119,81,204,1) 83.1%)"
      color="white"
      opacity={1}
      transition="all .5s ease-in-out"
      _hover={{
        bgImage: 'linear-gradient(109.6deg, rgba(157,75,199,1) 11.2%, rgba(119,81,204,1) 83.1%)',
        opacity: 0.75,
      }}
      _active={{
        bgImage: 'linear-gradient(109.6deg, rgba(157,75,199,1) 11.2%, rgba(119,81,204,1) 83.1%)',
        opacity: 0.9,
      }}
      onClick={onClickConnectBtn}
    >
      <Icon as={icon ? icon : IoWallet} mr={2} />
      {buttonText ? buttonText : 'Connect Wallet'}
    </Button>
  )
}

export const Disconnected = ({
  buttonText,
  onClick,
}: {
  buttonText: string
  onClick: MouseEventHandler<HTMLButtonElement>
}) => {
  return (
    <Button variant={'text'} onClick={onClick}>
      <Text
        fontWeight={500}
        _hover={{ color: 'secondary.base' }}
        color={useColorModeValue('black', 'white')}
        fontSize={{ sm: 8, lg: 16 }}
      >
        {buttonText.toUpperCase()}
      </Text>
    </Button>
    //<ConnectWalletButton buttonText={buttonText} onClickConnectBtn={onClick} />
  )
}

export const Connected = ({
  onClick,
}: {
  buttonText: string
  onClick: MouseEventHandler<HTMLButtonElement>
}) => {
  return (
    <Button variant={'text'} onClick={onClick}>
      <UsernameText style={{ fontWeight: 500 }} fontSize={{ sm: 8, lg: 16 }} />
    </Button>
    // <ConnectWalletButton buttonText={buttonText} onClickConnectBtn={onClick} />
  )
}

export const Connecting = () => {
  return (
    <Button variant={'text'}>
      <Spinner />
    </Button>
  )
}

export const Rejected = ({
  buttonText,
  wordOfWarning,
  onClick,
}: {
  buttonText: string
  wordOfWarning?: string
  onClick: MouseEventHandler<HTMLButtonElement>
}) => {
  const bg = useColorModeValue('orange.200', 'orange.300')

  return (
    <Button variant={'text'} onClick={onClick}>
      <Stack direction={'row'}>
        <Popover trigger={'hover'}>
          <PopoverTrigger>
            <Icon as={FiAlertTriangle} color={'yellow'} mt={0.5} />
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverHeader>Warning</PopoverHeader>
            {wordOfWarning && <PopoverBody>{wordOfWarning}</PopoverBody>}
          </PopoverContent>
        </Popover>

        <Text
          fontWeight={500}
          _hover={{ color: 'secondary.base' }}
          color={useColorModeValue('black', 'white')}
          fontSize={{ sm: 8, lg: 16 }}
        >
          {buttonText.toUpperCase()}
        </Text>
      </Stack>
    </Button>
  )
}

export const Error = ({
  buttonText,
  wordOfWarning,
  onClick,
}: {
  buttonText: string
  wordOfWarning?: string
  onClick: MouseEventHandler<HTMLButtonElement>
}) => {
  const bg = useColorModeValue('orange.200', 'orange.300')

  return (
    <Button variant={'text'} onClick={onClick}>
      <Stack direction={'row'}>
        <Popover trigger={'hover'}>
          <PopoverTrigger>
            <Icon as={FiAlertTriangle} color={'red'} mt={0.5} />
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverHeader>Warning</PopoverHeader>
            {wordOfWarning && <PopoverBody>{wordOfWarning}</PopoverBody>}
          </PopoverContent>
        </Popover>

        <Text
          fontWeight={500}
          _hover={{ color: 'secondary.base' }}
          color={useColorModeValue('black', 'white')}
          fontSize={{ sm: 8, lg: 16 }}
        >
          {buttonText.toUpperCase()}
        </Text>
      </Stack>
    </Button>
  )
}

export const NotExist = ({
  buttonText,
  onClick,
}: {
  buttonText: string
  onClick: MouseEventHandler<HTMLButtonElement>
}) => {
  return (
    <Button variant={'text'} onClick={onClick}>
      <Text
        fontWeight={500}
        _hover={{ color: 'secondary.base' }}
        color={useColorModeValue('black', 'white')}
        fontSize={{ sm: 8, lg: 16 }}
      >
        {buttonText.toUpperCase()}
      </Text>
    </Button>
  )
}

export const WalletConnectComponent = ({
  walletStatus,
  disconnect,
  connecting,
  connected,
  rejected,
  error,
  notExist,
}: {
  walletStatus: WalletStatus
  disconnect: ReactNode
  connecting: ReactNode
  connected: ReactNode
  rejected: ReactNode
  error: ReactNode
  notExist: ReactNode
}) => {
  switch (walletStatus) {
    case WalletStatus.Disconnected:
      return <>{disconnect}</>
    case WalletStatus.Connecting:
      return <>{connecting}</>
    case WalletStatus.Connected:
      return <>{connected}</>
    case WalletStatus.Rejected:
      return <>{rejected}</>
    case WalletStatus.Error:
      return <>{error}</>
    case WalletStatus.NotExist:
      return <>{notExist}</>
    default:
      return <>{disconnect}</>
  }
}
