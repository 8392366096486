import { Card, CardBody, CardHeader } from '@chakra-ui/card'

const WelcomeCard = () => (
  <Card>
    <CardHeader>Welcome to Kleomedes</CardHeader>
    <CardBody>Connect your wallet to start browsing the kleomedes cosmos</CardBody>
  </Card>
)

export default WelcomeCard
