import { useChain } from '@cosmos-kit/react'
import { defaultChainName } from '../config'
import { Text, useColorMode } from '@chakra-ui/react'
import React, { CSSProperties } from 'react'

export default function UsernameText({
  prefix,
  style,
  fontSize,
}: {
  prefix?: string
  style?: CSSProperties
  fontSize?: any
}) {
  const { username } = useChain(defaultChainName)
  const { colorMode } = useColorMode()
  const textShadowDark =
    '1px 1px 1px var(--chakra-colors-secondary-200),' +
    '0 0 1em var(--chakra-colors-secondary-700),' +
    '0 0 0.1em var(--chakra-colors-secondary-700)'
  const textShadowLight =
    '1px 1px 1px var(--chakra-colors-primary-400),' +
    '0 0 0.1em var(--chakra-colors-primary-300),' +
    '0 0 0.1em var(--chakra-colors-primary-300)'

  const text = username ? ' ' + username : 'EKKLESIA'
  return (
    <Text
      as={'span'}
      color="transparent"
      bgClip="text"
      textShadow={colorMode === 'dark' ? textShadowDark : textShadowLight}
      _hover={{ color: 'secondary.base', bgClip: 'unset', textShadow: 'unset' }}
      style={style}
      fontSize={fontSize}
    >
      {prefix} {text}
    </Text>
  )
}
