import { Box, Center, Text, useColorModeValue } from '@chakra-ui/react'
import React from 'react'
import KleoPrice from './price'

export default function Footer() {
  return (
    <Box as={'footer'} mb={8} px={{ base: 4, md: 8 }} mt={'auto'} textAlign={'center'}>
      <KleoPrice />
      <Center>
        <Text fontWeight={300} fontSize={'sm'} as={'span'}>
          © 2023 All rights reserved. Designed with love by
        </Text>
        <Text
          fontWeight={300}
          fontSize={'sm'}
          color={useColorModeValue('tertiary.base', 'secondary.base')}
          as={'span'}
        >
          &nbsp; Kleomedes Core Team.
        </Text>
      </Center>
    </Box>
  )
}
