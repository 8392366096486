import Head from 'next/head'
import { Box } from '@chakra-ui/react'
import Nav from './navbar'
import { ReactNode, useEffect, useState } from 'react'
import Footer from './footer'
import { useChain } from '@cosmos-kit/react'
import { defaultChainName } from '../config'

const siteTitle = 'Kleomedes Web App'

export default function Layout({
  children,
  chain = defaultChainName,
}: {
  children: ReactNode
  chain?: string
}) {
  const [isWaiting, setIsWaiting] = useState(true)
  const { isWalletConnected } = useChain(defaultChainName)

  useEffect(() => {
    setTimeout(() => {
      setIsWaiting(false)
    }, 3000)
  }, [])

  return (
    <>
      <Head>
        <link rel="icon" href="/favicon.ico" />
        <title>{siteTitle}</title>
      </Head>
      <Nav isWaiting={isWaiting && !isWalletConnected} chain={chain} />
      <Box maxH="$100vw" minH="$100vh" maxW="$100vw">
        {children}
      </Box>
      <Footer />
    </>
  )
}
